@tailwind base;
@tailwind components;
@tailwind utilities;

.body{
    background-color: black;
}

.grad {
    background: linear-gradient(94.55deg, #C3FB35 1.91%, #583382 120.51%);
}

